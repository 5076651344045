<template>
    <nav class="navbar navbar-expand-lg navbar-light vw-100"
        :style="{ background: navIsLucency ? 'transparent !important' : 'var(--theme) !important' }">

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="container h-100">
            <router-link to="/" class="navbar-brand">
                <!-- <img :src="LOGO ? LOGO : require('@/static/logo.png')" style="height: 5rem" alt="" /> -->
                <img :src="LOGO" style="height: 5.5rem" alt="" />
            </router-link>
            <div v-if="navMenu" id="navbarSupportedContent" class="collapse navbar-collapse h-100">
                <ul class="navbar-nav w-100 h-100">
                    <div v-for="(item, index) in NAV" :key="index">
                        <li v-if="item.type == 0" :class="['nav-item', index == NAV.length - 1 ? 'last-nav-item' : null]"
                            style="height:100%">
                            <router-link class="nav-link" :to="item.route">{{ item.title }}</router-link>
                            <div class="com-flex com-row-center active">
                                <div class="line" :style="{ width: item.route == presentActiveRoute ? '30%' : 0 }"></div>
                            </div>
                        </li>
                        <li v-if="item.type == 1" :class="['nav-item', index == NAV.length - 1 ? 'last-nav-item' : null]"
                            style="height:100%">
                            <a :href="item.route" target="blank">{{ item.title }}</a>
                        </li>
                    </div>
                </ul>
            </div>
        </div>
    </nav>
</template>


<script>
import { mapGetters } from "vuex";
export default {
    name: "Header",
    components: {},
    props: {
        // 导航栏是否透明
        navIsLucency: { type: Boolean, default: false },
        // 是否显示右侧菜单
        navMenu: { type: Boolean, default: false },
        // 当前页面路由
        presentActiveRoute: { type: String, default: "/" }
    },

    data() {
        return {}
    },

    computed: {
        ...mapGetters(["LOGO", "NAV"])
    },
    watch: {},

    created() { },
    mounted() { },

    methods: {}
}
</script>


<style scoped lang="scss">
.navbar {
    height: 9rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: all .3s;

    .container {
        position: relative;

        .navbar-brand {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
        }

        ul {
            justify-content: flex-end;
        }

        /* item */
        li {
            position: relative;

            a {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--white) !important;
                font-size: 1.7rem;
            }

            /* line */
            .active {
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 1rem;

                .line {
                    width: 0;
                    height: 3px;
                    border-radius: 3px;
                    background: var(--white);
                    // background: var(--theme);
                    transition: width .1s;
                }
            }
        }

        // .nav-link,a{
        //     color: #000 !important;
        // }
    }
}

@media (min-width: 1200px) {
    .navbar {
        padding: .5rem 5rem;

        .nav-item {
            padding: 0 1.5rem 0 1.5rem !important;
        }

        .last-nav-item {
            padding-right: 0 !important;
        }

        .nav-link {
            padding: 0 !important;
        }
    }
}</style>