<!--
 * @Author: ranjianglai
 * @Date: 2022-08-16 13:26:38
 * @LastEditTime: 2023-07-21 15:11:38
 * @Description: Footer
-->


<template>
    <footer>
        <div class="footer">
            <div class="container">
                <div v-if="footer.length" class="row">
                    <div v-for="(tem, ind) in footer" :key="ind" class="col-4 col-sm-3 col-md tem">
                        <p class="text-center">
                            <router-link :to="tem.path">{{ tem.name }}</router-link>
                        </p>
                        <div class="com-m-t-20 com-flex com-row-center">
                            <div class="line"></div>
                        </div>
                        <ul v-if="tem.list.length != 0">
                            <li v-for="(item, index) in tem.list" :key="index" class="text-center">
                                <router-link v-if="!item.hide" :to="item.path">{{ item.name  }}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="com-font-14 com-assist-color beian">
            <div class="container com-flex com-flex-col com-row-center">
                <div v-if="$config.infoWebsite && $config.infoWebsite.length" class="com-m-b-10 com-flex">
                    <a v-for="(item, index) in $config.infoWebsite" :key="index" target="_blank" :href="item.url">
                        {{ item.title }}
                    </a>
                </div>
                <div class="com-flex">
                    <span v-if="$config.copyright" class="com-m-r-20">版权所有：{{ $config.copyright }}</span>
                    <span v-if="$config.technicalSupport" class="com-m-r-20">技术支持：{{ $config.technicalSupport }}</span>
                    <a v-if="$config.beianNo" class="com-font-14" target="_blank" href="https://beian.miit.gov.cn">{{
                        $config.beianNo }}
                    </a>
                    <img width="15" style="margin-right: 10px;" src="../assets/beian.png" alt="">
                    <a class="com-font-14" target="_blank" href= "https://beian.mps.gov.cn/#/query/webSearch?code=63280102000073">
                        青公网安备63280102000073
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>


<script>
import { projectGeneral, newsClass, activityClass } from "@/request/api.js";
import config from "@/config/index.js";
export default {
    name: "Footer",
    components: {},
    props: {},

    data() {
        return {
            footer: [],
            token:localStorage.getItem('Authorization')
        }
    },

    computed: {},
    watch: {},

    created() { },
    mounted() {
        // 处理数据
        this.init();
    },

    methods: {
        // 处理数据
        init(){
            const list = JSON.parse(sessionStorage.getItem("MODULELIST"));
            const footer = [...config.footer];
            list.map(v=>{
                footer.map(a=>{
                    if(v.route == a.path){
                        a.name = v.title;
                    } 
                })
            })
            this.footer = footer;
            if(this.token){
                footer[footer.length-1].list[0].hide = true;
                footer[footer.length-1].list[1].hide = true;
            }
            // 项目概况
            this.getProjectGeneral();
            // 资讯分类
            this.getNewsClass();
            // 活动分类
            // this.getActivityClass();
        },

        // 项目概况/general
        getProjectGeneral() {
            let index = 0;
            config.footer.map((v, i) => {
                if (v.path == "/general") index = i;
            })
            projectGeneral().then(res => {
                console.log("项目概况", res)
                config.footer[index].list = []
                res.map((v, i) => {
                    if (i < 5) {
                        config.footer[index].list.push({
                            name: v.title,
                            path: "/general?current=" + i
                        })
                    }
                })
            })
        },

        // 旅游资讯/news
        getNewsClass() {
            let index = 0;
            config.footer.map((v, i) => {
                if (v.path == "/news") index = i;
            })
            newsClass().then(res => {
                console.log("资讯分类", res);
                config.footer[index].list = [];
                res.map((v, i) => {
                    if (i < 4) {
                        config.footer[index].list.push({
                            name: v.name,
                            path: "/news?code=" + v.code
                        })
                    }
                })
            });
        },

        // 活动分类/activity
        getActivityClass() {
            activityClass().then(res => {
                console.log("活动分类", res);
            })
        }
    }
}
</script>


<style scoped lang="scss">
.footer {
    background: #1F1F1F;

    a {
        color: #DFDFDF;
        transition: color .5s;

        &:hover {
            color: var(--white) !important;
        }
    }

    .row {
        padding: 6rem 0 !important;

        .line {
            width: 56px;
            height: 1px;
            background: #3B3B3B;
        }

        ul {
            margin-top: 2rem !important;

            li {
                margin: 5px 0 !important;

                a {
                    font-size: 14px;
                    color: #999999;
                }
            }
        }

        @media (max-width: 576px) {
            .tem {
                margin-top: 3rem;
            }
        }
    }
}

/* 备案 */
.beian {
    background: #000;
    padding: 3.2rem 0;

    a {
        font-size: 14px;
        margin-right: 1rem;
        color: #999;

        &:hover {
            color: #fff;
        }
    }
}
</style>