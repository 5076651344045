<!--
 * @Author: ranjianglai
 * @Date: 2022-08-16 13:26:38
 * @LastEditTime: 2023-09-05 11:19:38
 * @Description: 首页
-->


<template>
  	<div class="content">
		<!-- Banner -->
		<section>
			<!-- 实景展示 -->
			<div v-if="liveActionList.length" class="iframe">
				<el-carousel indicator-position="none" :autoplay="false">
					<el-carousel-item v-for="(item, index) in liveActionList" :key="index">
						<iframe :id="'live-action' + index" class="vw-100 vh-100" :src="item.url" frameborder="0"></iframe>
					</el-carousel-item>
				</el-carousel>
				<div class="custom">
					<img width="994px" class="animate__animated animate__flipInX" :src="WELCOMEIMG" alt="animate__delay-2s" /> 
					<div style="margin-top: 5rem; z-index: 100;">
						<div class="explore animate__animated animate__flipInX" @click="scorll">开始探索</div>
					</div>
				</div>
			</div>
			<!-- 轮播图 -->
			<div v-else class="carousel">
				<el-carousel indicator-position="none" :autoplay="true">
					<el-carousel-item v-for="(item, index) in bannerData.bannerList ? bannerData.bannerList : bannerList" :key="index">
						<img :src="item" class="d-block vw-100 vh-100" alt="" />
					</el-carousel-item>
				</el-carousel>
				<div class="custom">
					<img width="994px" class="animate__animated animate__flipInX" :src="WELCOMEIMG" alt="animate__delay-2s" /> 
					<div style="margin-top: 5rem; z-index: 100;">
						<div class="explore animate__animated animate__flipInX" @click="scorll">开始探索</div>
					</div>
				</div> 
			</div>
		</section>

		<!-- 印象察尔汗 -->
		<section class="impression">
			<div class="container">
				<div class="title com-font-26 com-font-bold">印象察尔汗</div>
				<div class="row">
					<div v-for="(item,index) in impressions" :key="index" class="col col-12 col-lg-6 col-xl-4">
						<div class="item">
							<p class="title com-font-20">{{item.title}}</p>
							<p class="sub_title com-m-t-30 com-font-14 com-minor-color">{{item.sub_title}}</p>
							<img src="../static/home/impression-bg.png" alt="" />
						</div>
					</div>
					<div class="w-100 com-flex com-row-center" style="margin-top: 4rem" >
						<button type="button" class="com-m-t-24 btn btn-outline-secondary" 
							@click="skip('/general')">
							开始探索
						</button>
					</div>
				</div>
			</div>
		</section>
		
		<!-- 其他 -->
		<section class="other">
			<div class="h-100 container com-flex com-flex-col com-row-center">
				<p class="com-font-30 com-font-bold">你的梦想我们竭尽所能</p>
				<p class="com-m-t-30 com-font-18 text-center">
					在线浏览察尔汗大美盛景,感受壮丽景色带来的心灵震撼，我们时刻都在关注您的旅游体验
				</p>
				<div class="w-100 row">
					<div v-for="(item, index) in others" :key="index" @click="skip(item.path)"
						@mouseenter="otherIndex = index" @mouseleave="otherIndex = null"
						class="col com-flex com-col-center com-cursor-pointer">
						<div :class="['round_dot', index == otherIndex ? 'animate__animated animate__flipInX' : null]"></div>
						<span :class="['com-font-14', index == otherIndex ? 'animate__animated animate__flipInX' : null]">
							{{ item.name }}
						</span>
					</div>
				</div>
			</div>
		</section>

		<!-- 热门活动 -->
		<section class="activity">
			<div class="container">
				<div class="com-flex com-row-between">
					<h2 class="com-font-20 com-font-bold">热门活动</h2>
					<div v-if="hotActivityList.length > 3" class="com-flex com-cursor-pointer" @click="skip('/activity')">
						<span class="com-m-r-4 com-font-14 com-minor-color">查看更多</span>
						<img src="@/static/right.png" width="15" height="15" alt="">
					</div>
				</div>
				<div class="row">
					<div v-for="(item,index) in hotActivityList" :key="index" class="col col-12 col-lg-6 col-xl-4">
						<div class="item com-cursor-pointer" 
							@mouseenter="activityCurrent=index" @mouseleave="activityCurrent=null">
							<h5 class="com-font-18 com-font-bold com-line-1">{{item.title}}</h5>
							<p class="com-m-t-14 com-font-12 com-minor-color">
								活动时间:{{item.startDate}}~{{item.endDate}}
							</p>
							<img :class="['com-m-t-20',index==activityCurrent ? 'animate__animated animate__pulse' : null]" 
								:src="item.logo" width="100%" style="height:25.5rem;object-fit:cover" alt="" />
							<p class="com-m-t-20 com-font-14 com-line-2">{{item.introduce ? item.introduce : '暂无活动简介'}}</p>
							<div class="text-center">
								<button type="button" class="com-m-t-24 btn btn-outline-secondary" 
									@click="goActivityDetail(item.id)">
									了解更多
								</button>
							</div>
						</div>
					</div>
					<div v-if="hotActivityList.length==0" class="w-100 com-flex com-row-center">
						<el-empty :image-size="200"></el-empty>
					</div>
				</div>
			</div>
		</section>

		<!-- 智游景区 -->
		<section class="play">	
			<div class="h-100 container com-flex com-flex-col com-row-center">
				<h2 class="com-font-20 com-font-bold">智游景区</h2>
				<p class="com-m-t-30 com-font-14 text-center" style="line-height:28px" v-html="play_text"></p>
				<div class="w-100 row">
					<div v-for="(item,index) in plays" :key="index" @click="skip(item.path)"
						@mouseenter="playCurrent=index" @mouseleave="playCurrent=null"
						class="col com-m-b-20 com-flex com-flex-col com-row-center com-cursor-pointer">
						<img :src="item.icon" style="width:8rem;height:8rem" 
							:class="[index==playCurrent ? 'animate__animated animate__flipInY' : null]" />
						<span :class="['com-m-t-10 com-font-14',index==playCurrent ? 'animate__animated animate__flipInY' : null]">
							{{item.name}}
						</span>
					</div>
				</div>
			</div>
		</section>

		<!-- 直播探头 -->
		<section class="live-list">
            <div class="container">
                <div class="com-flex com-row-between">
                    <h2 class="com-font-20 com-font-bold">直播探头</h2>
                    <div v-if="liveStreamingProbe.length == 6" class="com-flex com-cursor-pointer" @click="skip('/live/list')">
                        <span class="com-m-r-4 com-font-14 com-minor-color">查看更多</span>
                        <img src="@/static/right.png" width="15" height="15" alt="">
                    </div>
                </div>
                <div class="row list">
                    <!-- List -->
                    <div v-for="(item,index) in liveStreamingProbe" :key="index" 
                        class="item col-12 col-lg-6 col-xl-4"
                        @mouseenter="liveAnimateIndex=index" @mouseleave="liveAnimateIndex=null">
                        <img :class="[index == liveAnimateIndex ? 'animate__animated animate__fadeInUp' : null]"
                            width="100%" style="height:27.8rem" :src="item.logo ? item.logo : require('@/static/empty.jpeg')" alt="" />
                        <div :class="['introduction',index == liveAnimateIndex ? 'animate__animated animate__fadeInDown' : null]">
                            <div class="name">{{ item.name }}</div>
                            <div class="com-line-1 t">{{item.sceneryName}}</div>
                        </div>
                        <!-- 播放图标 -->
                        <img class="live-icon" src="@/static/Live/live-icon.png" width="68" height="68" alt="" @click="play(item.url)" />
                        <!-- 左上角直播动图 -->
                        <div class="left-top">
                            <div class="lines" style="--h: 80px">
                                <div class="line" style="--d: 1"></div>
                                <div class="line" style="--d: 0"></div>
                                <div class="line" style="--d: 1"></div>
                            </div>
                            <span>直播中</span>
                        </div>
                    </div>
                    <!-- Empty -->
                    <el-empty v-if="liveStreamingProbe.length==0" style="margin:3rem auto auto" description="暂未查询到相关数据"></el-empty>
                </div>
            </div>
        </section>
		<!-- 模态框 -->
        <div class="live-modal" :style="{
			width: liveModalUrl ? '100vw' : 0, 
			height: liveModalUrl ? '100vh' : 0,
			top: liveModalUrl ? 0 : '50%', 
			left: liveModalUrl ? 0 : '50%'}">
            <div class="player" ref="liveModal" id="liveModal"></div>
            <i v-if="liveModalUrl" class="el-icon-error close" @click="close"></i>
        </div>

		<!-- 旅游助手 -->
		<!-- <section class="travel-assistant">
			<div class="swiper-container">
				<div class="swiper-wrapper">
					<div :class="['swiper-slide com-cursor-pointer', 'swiper-slide-' + index]" 
						v-for="(item,index) in travels" :key="index"
						@click="skip(item.path)">
						<img :src="item.src" alt="">
						<span class="text">{{ item.name }}</span>
					</div>
				</div>
				<div class="swiper-button-prev">
					<img :src="require('@/static/home/left.png')" alt="">
				</div>
				<div class="swiper-button-next">
					<img :src="require('@/static/home/right.png')" alt="">
				</div>
			</div>
		</section> -->

		<!-- 友情链接 -->
		<section v-if="friendships.length" class="friendship">
			<div class="h-100 container com-flex com-flex-col">
				<h2 class="com-font-20 com-font-bold">友情链接</h2>
				<h4 class="com-m-t-20">感谢长期合作的客户，我们携手共同创造价值与资本增益</h4>
				<div class="row">
					<div v-for="(item,index) in friendships" :key="index" @click="externalLinks(item.url)"
						class="col com-flex com-row-center com-cursor-pointer item"
						@mouseenter="friendshipCurrent=index" @mouseleave="friendshipCurrent=null">
						<img :class="[index==friendshipCurrent ? 'animate__animated animate__pulse' : null]" :src="item.logo" width="190" height="68" />
					</div>
				</div>
			</div>	
		</section>
	</div>
</template>


<script>
import configData from "@/config/data.js";
import { mapGetters } from "vuex";
import { liveActionList, hotActivity, livePage, linksList } from "@/request/api.js";
import DPlayer from "dplayer";
import Hls from "hls.js";
import Swiper from "swiper";
export default {
	name: "Home",
	components: {},
	props: {},
	data() {
		return {
			bannerData: {},
			// 实时展播
			liveActionList: [],
			bannerList: [
				require("@/static/bg/h-general.png")
			],
			// 印象察尔汗
			impressions: [
				{title: "盐科斯特地貌", sub_title: "不可思议的中国最大盐湖，也是世界上最著名的内陆盐湖之一"},
				{title: "举世罕见的异样美丽", sub_title: "蓝天白云，浩瀚的湖面犹如一个巨大的宝藏，波光潋滟，无边无际"},
				{title: "大自然的馈赠", sub_title: "鬼斧神工一般的盐花，晶莹剔透，千姿百态，把盐湖装点得美若仙境"},
			],
			// 其他
			others: [
				{ name: "720全景", path: "/media/video?type=liveAction" },
				{ name: "景点介绍", path: "/list?type=SPOT" },
				{ name: "网红打卡", path: "/list?type=SPOT" },
				{ name: "实景展播", path: "/live" },
				{ name: "美景图库", path: "/media/image" },
				// { name: "天籁之音", path: "/media/video" },
				{ name: "宣传视频", path: "/media/video" },
			],
			otherIndex: null,
			// 热门活动
			hotActivityList: [],
			activityCurrent: null,
			// 智游景区
			play_text: configData.play_text,
			plays: [
				{ icon: require("@/static/home/play1.png"), name: `游在${this.$config.name}`, path: "/list?type=SPOT" },
				{ icon: require("@/static/home/play2.png"), name: `住在${this.$config.name}`, path: "/list?type=HOTEL" },
				{ icon: require("@/static/home/play3.png"), name: `吃在${this.$config.name}`, path: "/list?type=DINING" },
				{ icon: require("@/static/home/play4.png"), name: `购在${this.$config.name}`, path: "/list?type=SHOPPING" },
				{ icon: require("@/static/home/play5.png"), name: `娱在${this.$config.name}`, path: "/list?type=RECREATION" },
			],
			playCurrent: null,
			// 直播探头
            liveStreamingProbe: [],
			liveAnimateIndex: null,
            liveModalUrl: null,  
			Player: {},
			// 旅游助手
			travels: [
				{ src: require('@/static/TravelGuide/weather.png'), name: '天气查询', path: '/travelguide/weather' },
                { src: require('@/static/TravelGuide/complaint.png'), name: '在线投诉', path: '/travelguide/complaint' },
                { src: require('@/static/TravelGuide/trip.png'), name: '出行指南', path: '/travelguide/trip' },
			],
			// 友情链接
			friendships: [],
			friendshipCurrent: null,
		}
	},

	computed: {
        ...mapGetters(["WELCOMEIMG"])
    },
	watch: {},

	created() {},
	mounted() {
		// 实景展播列表
		this.getLiveActionList();
		// 设置banner
		this.setBanner();
		// 热门活动
		this.getHotActivity();
		// 直播探头
		this.liveList();
		// 初始化旅游助手
		this.initTravel();
		// 友情链接
		this.getLinksList();
	},

	methods: {
		// 实景展播
		getLiveActionList(){
			liveActionList().then(res=>{
				console.info("实景展播",res);
				this.liveActionList = [];
				if(res && res.length){
					res.map((v,i)=>{
						if(i < 3) this.liveActionList.push(v);
					})
				}
			})
		},

		// 设置banner
		setBanner(){
			const list = JSON.parse(sessionStorage.getItem("MODULELIST"));
			list.map(v=>{
				if(v.route == this.$route.path) this.bannerData = v;
			})
		},

		// 热门活动
		getHotActivity(){
			this.hotActivityList = [];
			hotActivity().then(res=>{
				console.log("热门活动",res);
				res.map((v,i)=>{
					if(i < 3) this.hotActivityList.push(v);
				})
			})
		},

        // 直播探头
        liveList(){
            livePage({page:1,limit:6,type:1}).then(res=>{
                console.info("直播探头",res.records);
                this.liveStreamingProbe = res.records;
            })
        },
		// 播放
		play(url){
            this.liveModalUrl = url;
			this.Player = new DPlayer({
				container: document.getElementById("liveModal"),
				live: true,
				autoplay: true,
				theme: "#FADFA3", // 主题色
				loop: true, // 视频是否循环播放
				lang: "zh-cn",
				screenshot: true,// 是否开启截图
				hotkey: true, // 是否开启热键
				preload: "metadata",//  视频是否预加载
				volume: 0.7,// 默认音量
				mutex: false,
				video: {
					url: this.liveModalUrl,
					type: "customHls",
					customType: {
						customHls: function(video) {
							const hls = new Hls();
							hls.loadSource(video.src);
							hls.attachMedia(video);
						}
					}
				},
			})
        },
		// 关闭模态框
		close(){
			this.Player.destroy();
			this.Player = null;
            this.liveModalUrl = null;
        },

		// 初始化旅游助手
		initTravel(){
			this.$nextTick(()=>{
				const mySwiper = new Swiper (".swiper-container", {
					loop: true,
					autoplay: {
						autoplay: true,
						pauseOnMouseEnter: true,
					},
					slidesPerView: 3,
					spaceBetween: 30,
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
				})  
				mySwiper.el.onmouseover = function(){
					mySwiper.autoplay.stop();
				}
				mySwiper.el.onmouseout = function(){
					mySwiper.autoplay.start();
				}
			})
		},

		
		// 友情链接
		getLinksList(){
			this.friendships = [];
			linksList().then(res=>{
				console.info("友情链接",res);
				if(res && res.length) this.friendships = res;
			})
		},

		// 跳转
		skip(path){
			this.$router.push(path); 
		},

		// 活动详情页
		goActivityDetail(id){
			this.$router.push({ 
				path: "/list/detail",
				query: {
					type: "ACTIVITY",
					id: id
				}
			})
		},

		// 友情链接
		externalLinks(url){
			if(url){
				window.open(url);
			}else{
				this.$notify({ type: "warning", message: "暂无外部链接地址" })
			}
		},

		// 页面滚动
		scorll(){
			$("body,html").animate({
				scrollTop: document.documentElement.clientHeight - 90
			},300)
		}
	}
}
</script>


<style scoped lang="scss">
	/* Banner */
	.iframe,.carousel{
		position: relative;
		overflow: hidden;
		.custom{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			z-index: 1000;
			.explore{
				width: 200px;
				height: 60px;
				background: rgba(255,255,255,0.1);
				border-radius: 96px;
				border: 1px solid rgba(255,255,255,0.8);
				font-size: 20px;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				transition: all .5s;
				&:hover{
					// background: var(--theme);
					background: rgba(0,0,0,.1);
					border: 1px solid #fff;
				}
			}
		}
	}

	/* 印象察尔汗 */
	.impression{
		padding: 8rem 0;
		.title{
			text-align: center;
		}
		.row{
			margin-top: 4rem;
			.item{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: relative;
				padding: 2rem 2.5em;
				img{
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0,
				}
				.title{
					position: relative;
					&:after{
						content: "";
						width: 40px;
						height: 3px;
						background: #555;
						border-radius: 6px;
						position: absolute;
						left: 50%;
						bottom: -10px;
						transform: translateX(-50%);
					}
				}
				.sub_title{
					text-align: center;
				}
			}
			.btn{
				font-size: 14px;
				padding: 8px 30px;
			}
		}
	}

	/* 其他 */
	.other{
		height: 800px;
		background: url("../static/state/other-bg.png") no-repeat center center;
		background-size: cover;
		color: var(--white);
		.row{
			margin-top: 6rem !important;
			.round_dot{
				width: 1.5rem;
				height: 1.5rem;
				margin-right: 1rem;
				border-radius: 50%;
				border: 1px solid #fff;
			}
		}
	}

	/* 热门活动 */
	.activity{
		padding: 6rem 0;
		.row{
			margin-top: 3rem;
			.item{
				background: #EEEEEE;
				padding: 3.5rem 3rem;
				transition: background .6s;
				border-radius: 6px;
				img{
					border-radius: 6px;
				}
				&:hover{
					background: var(--theme);
					color: var(--white);
					p{
						color: var(--white);
					}
					.btn{
						border: 1px solid var(--white);
						color: var(--white);
					}
				}
				.btn{
					font-size: 14px;
					padding: 8px 16px;
				}
			}
		}
		
	}

	/* 智游景区 */
	.play{
		height: 600px;
		background: url("../static/state/play-bg.png") no-repeat center center;
		background-size: cover;
		color: var(--white);
		.row{
			margin-top: 6rem !important;
		}
	}

	/* 直播探头 */
	.live-list{
		padding: 6rem 0;
        .list{
            .item{
                margin-top: 3rem;
                cursor: pointer;
                position: relative;
                .introduction{
                    background: var(--white);
                    padding: 10px 20px;
                    transition: all .5s, color .2s;
                    .name{
                        font-size: 18px;
                        font-weight: bold;
                    }
                    .t{
                        font-size: 14px;
                        color: #666;
                    }
                }
                &:hover{
                    .introduction{
                        background: var(--theme);
                        color: var(--white);
                    }
                    .t{
                        color: var(--white);
                    }
                }
                .live-icon{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-100%);
                }
                .left-top{
                    position: absolute;
                    top: 10px;
                    left: 30px;
                    background: linear-gradient(90deg, #FE9A0B 0%, #FF7D0E 100%);
                    border-radius: 2px;
                    color: var(--white);
                    padding: 4px 6px;
                    display: flex;
                    span{
                        font-size: 12px;
                    }
                    .lines{
                        margin-right: 4px;
                        display: flex;
                        align-items: center;
                        .line{
                            background: #fff;
                            width: 2px;
                            height: 20%;
                            border-radius: calc(var(--h) * 0.2 * 0.5);
                            margin: 0 1px;
                            animation: loading 1s infinite linear;
                            animation-delay: calc(0.2s * var(--d));
                        }
                    }
                    @keyframes loading {
                        0% {
                            background: #fff;
                            height: 20%;
                            border-radius: calc(var(--h) * 0.2 * 0.5);
                        }
                        50% {
                            background: #fff;
                            height: 60%;
                            border-radius: calc(var(--h) * 1 * 0.5);
                        }
                        100% {
                            background: #fff;
                            height: 20%;
                            border-radius: calc(var(--h) * 0.2 * 0.5);
                        }
                    }
                }
            }
        }
	}
	.live-modal{
        width: 0;
        height: 0;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        background: rgba(0,0,0,.8);
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .1s;
        .player{
            width: 72%;
            height: 66%;
            border-radius: 8px;
        }
        .close{
            position: absolute;
            top: 17%;
            right: 14%;
            font-size: 32px;
            margin-top: -16px;
            margin-right: -16px;
            cursor: pointer;
            transition: all .5s;
            &:hover{
                color: red;
                transform: rotate(180deg);
            }
        }
    }

	/* 旅游助手 */
	.travel-assistant{
		padding-bottom: 6rem;
		margin-left: -15%;
		background: #F7F8F9;
		.swiper-slide{
			position: relative;
			img{
				width: 100%;
				height: 36rem;
			}
			.text{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				font-size: 20px;
				color: #fff;

			}
		}
		.swiper-slide-active{
			width: 40% !important;
		}
		.swiper-button-prev,.swiper-button-next{
			width: auto;
			height: auto;
			margin-top: 0;
			transform: translateY(-50%);
			img{
				width: 20px;
				height: 40px;
			}
			&:after{
				display: none;
			}
		}
		.swiper-button-prev{
			left: calc(5rem + 13%);
		}
		.swiper-button-next{
			right: 5rem;
		}
	}

	/* 友情链接 */
	.friendship{
		width: 100%;
		height: 590px;
		background: url("../static/state/friendship-bg.png") no-repeat center center;
		background-size: cover;
		.container{
			margin-top: -18rem;
			padding-bottom: 10rem !important;
			justify-content: flex-end;
			.row{
				margin-top: 5rem !important;
				.item{
					margin-top: 3rem;
				}
			}
		}
	}
</style>
<style>
	/* 轮播 */
	.el-carousel__container{
		height: 100vh !important;
	}
</style>