// 导出
export default {
    methods:{
		// 生成随机颜色
		getFirstLevelTitle(){
			const path = this.$route.path;
			const list = JSON.parse(sessionStorage.getItem("MODULELIST"));
            let title = "察尔汗盐湖景区";
			for (let index = 0; index < list.length; index++) {
				if(list[index].route != "/"){
					if(path.indexOf(list[index].route) != -1){
						title = list[index].title;
                        break;
					}
				}
				if(list[index].route == "/resource"){
					title = list[index].title;
					break;
				}
			}
            return title;
		}
    }
}