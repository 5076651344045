const name = "察尔汗";

export default{
    // 解密aesKey
    aesKey: "uwTZwrCZYh7zKROK",

    // 察尔汗
    baseUrl: "https://www.mhyhly.com/api",
    authUrl: "https://www.mhyhly.com/auth/user/",
    app_id: "qoUVUKVpIAHnqULxAOjv",  // 项目标识
    publicPath: "", // 资源前缀
    name: name, // 项目名称
    decrypt: true, // 是否加密
    bannerTitle: true, // 是否显示bannerTitle

    // 默认导航栏
    nav: [
        { title: "首页", route: "/", type: 0 },
        { title: `初识${name}`, route: "/general", type: 0 },
        { title: `感知${name}`, route: "/media", type: 0 },
        { title: `智游${name}`, route: "/resource", type: 0 },
        { title: "旅游资讯", route: "/news", type: 0 },
        { title: "旅游活动", route: "/activity", type: 0 },
        { title: "旅游指南", route: "/travelguide", type: 0 },
        { title: "个人中心", route: "/mine", type: 0 }
    ],

    // 默认页尾
    footer: [
        { 
            name: `初识${name}`, path: "/general",
            list: [] // 动态渲染
        },
        { 
            name: `感知${name}`, path: "/media",
            list: [
                { name: "美景图库", path: "/media/image" },
                { name: "精彩视频", path: "/media/video" },
                { name: "虚拟体验", path: "/media/video?type=liveAction" },
                { name: "旅游攻略", path: "/media/strategy" },
                { name: "旅游路线", path: "/media/line" },
                { name: "景区手绘", path: "/media/gis" },
            ]
        },
        { 
            name: `智游${name}`, path: "/resource" ,
            list: [
                { name: "热门景点", path: "/list?type=SPOT" },
                { name: "特色美食", path: "/list?type=FOOD" },
                { name: "酒店住宿", path: "/list?type=HOTEL" },
                { name: "旅游特产", path: "/list?type=SPECIALTY" },
                { name: "休闲娱乐", path: "/list?type=RECREATION" },
            ]
        },
        { 
            name: "旅游资讯", path: "/news" ,
            list: [] // 这里动态渲染
        },
        { 
            name: "旅游活动", path: "/activity" ,
            list: [] // 这里动态渲染
        },
        { 
            name: "旅游指南", path: "/travelguide" ,
            list: [
                { name: "天气查询", path: "/travelguide/weather" },
                { name: "在线投诉", path: "/travelguide/complaint" },
                { name: "出行指南", path: "/travelguide/trip" },
            ]
        },
        { 
            name: "个人中心", path: "/mine" ,
            list: [
                { name: "注册", path: "/regist" },
                { name: "登陆", path: "/login" },
                { name: "个人资料", path: "/mine" },
                // { name: "安全设置", path: "" },
            ]
        }
    ],

    // 分页
    paging:{
        page: 1,
        limit: 9
    }
}