<!--
 * @Author: ranjianglai
 * @Date: 2022-08-16 13:26:38
 * @LastEditTime: 2023-09-05 11:54:46
 * @Description: 入口文件
-->


<template>
	<div id="app">
		<!-- Nav -->
		<HeaderView :navIsLucency="navIsLucency" :navMenu="navMenu" :presentActiveRoute="presentActiveRoute" />

		<!-- 顶部安全距离 -->
		<div v-if="($route.path != '/') && navMenu" class="com-top-safety-distance"></div>

		<!-- 路由 -->
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" />
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" />

		<!-- 页脚 -->
		<FooterView v-if="navMenu" />

		<!-- 回到顶部 -->
		<el-backtop target="" :bottom="90"></el-backtop>
		
		<!-- 侧边栏 -->
		<section v-if="sidebarLists.length" class="suspension-box">
			<div v-for="(tem,ind) in sidebarLists" :key="ind" 
				class="suspension-item">
				<div v-if="tem.status == 1">
					<span>{{tem.title}}</span>
					<div v-if="tem.type == 1" class="position-box" v-html="tem.content"></div>
					<div v-if="tem.type == 2" class="position-box">
						<img :src="tem.content" alt="" />
					</div>
				</div>
			</div>
		</section>
	</div>
</template>


<script>
import HeaderView from "@/components/HeaderView.vue";
import FooterView from "@/components/FooterView.vue";
import { viewRecord, systemSeting, indexConfig, moduleList, sidebarList } from "@/request/api.js";
import config from "@/config/index";
import { AesEncrypt } from "@/common/encryption";
export default {
    name: "App",
    components: {
		HeaderView,
		FooterView
	},
    props: {},

    data() {
        return {
			// 头部导航栏是否透明(默认透明)
			navIsLucency: true,
			// 是否显示右侧菜单(默认不显示)
			navMenu: false,
			// 当前激活路由
			presentActiveRoute: "null",
			// 窗口高度
			winHeight: 1080,
			// 侧边栏数据
			sidebarLists:[],
		};
    },

    computed: {},
    watch: {
		"$route": "getPath"
	},

    created() {},
	mounted () {
		// 获取路由
		this.getPath();
		// 获取窗口宽高
		this.getWindowInfo();
		window.onresize = () => {
			this.getWindowInfo();
		}
		// 滚动页面监听
		this.$nextTick(() => {
			window.addEventListener("scroll", this.handleScroll);
		})
		// 初始化
		if(config.decrypt){
			if(sessionStorage.getItem("SYSTEMSETINFO")){
				this.init();
			}else{
				systemSeting().then(res=>{
					console.log("项目配置",res);
					sessionStorage.setItem("SYSTEMSETINFO", AesEncrypt(JSON.stringify(res),config.aesKey));
					this.init();
				})
			}
		}else{
			this.init();
		}
		// 网站访问量
		setTimeout(()=>{
			if(sessionStorage.getItem("viewRecord") == null) {
				viewRecord().then(()=>{
					sessionStorage.setItem("viewRecord","true");
				})
			}
		},3000)
    },

    methods: {
		// 初始化
		init(){
			indexConfig().then(res=>{
				console.log("首页配置",res);
				if(res){
					const link = document.querySelector('link[rel*="icon"]');
					link.href = res.favicon; // ico
					document.title = res.title ? res.title : this.$config.name; // title
					config.infoWebsite = res.infoWebsite; // 链接
					config.copyright = res.copyright; // 版权所有
					config.technicalSupport = res.technicalSupport; // 技术支持
					config.beianNo = res.beianNo; // 备案号
					this.$store.dispatch("setLogo", res.logo); // logo
					this.$store.dispatch("setWelcomeImg", res.welcomeImg); // welcomeImg
				}
			})
			moduleList().then(res=>{
				if(res && res.length){
					console.log("栏目配置",res);
					const nav = [];
					res.map(v=>{
						nav.push({
							title: v.title,
							route: v.route,
							type: v.type
						})
					})
					this.$store.dispatch("setNav", nav);
					sessionStorage.setItem("MODULELIST", JSON.stringify(res));
				}else{
					this.$store.dispatch("setNav", config.nav); 
				}
			})
			sidebarList().then(res=>{
				console.log("侧边栏配置",res);
				this.sidebarLists = res;
			})
		},

		// 获取路由
		getPath(){
			const route = this.$route.path;
			// 导航栏是否透明以及是否显示右侧菜单
			if (route == "/" || route == "/travelguide/complaint" || route == "/travelguide/weather" || route == "/login") {
				this.navIsLucency = true;
				this.navMenu = false;	
			} else {
				this.navIsLucency = false;
				this.navMenu = true	;
			}
			// 激活
			this.presentActiveRoute = route == "/" ? "/" : route.match("(/[a-z]+[^/]){1}")[0];
		},

		// 获取窗口宽高
		getWindowInfo(){
			this.winHeight = document.documentElement.clientHeight;
		},

		// 页面滚动
		handleScroll(e) {
			const route = this.$route.path;
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			if(route == "/"){
				if (scrollTop == this.winHeight - 90 || scrollTop > this.winHeight - 90) {
					this.navIsLucency = false;
					this.navMenu = true;
				}else{
					this.navIsLucency = true;
					this.navMenu = false;
				}
			}
		}
	}
}
</script>


<style lang="scss">
// 重置样式
@import './assets/css/reset.css';
// 自定义字体
@import './assets/fonts/fonts.css';
// 公共样式
@import './assets/css/styles.scss';


// element-ui
.el-notification__content{
	margin-top: 0 !important;
	font-family: Regular, '思源黑体', Avenir, Helvetica, Arial, sans-serif;
}
// 分页
.el-pagination{
	margin-top: 3rem;
	display: flex;
	justify-content: flex-end;
}
// loading
.el-loading-mask{
	background: transparent !important;
	display: flex;
	justify-content: center;
	font-family: Regular, '思源黑体', Avenir, Helvetica, Arial, sans-serif;
	.el-loading-spinner{
		border-radius: 6px;
		padding: 12px;
		background: rgba(0, 0, 0, 0.7) !important;
		width: 160px;
		.el-loading-text{
			margin-top: 6px !important;
			text-align: center;
		}
	}
}
// 空数据
.el-empty{
	padding: 0 !important;
}
//perview
.el-image-viewer__mask{
	opacity: 1 !important;
}
.el-image-viewer__close{
	display: none !important;
}


html,body{
	width: 100%;
	height: 100%;
	::-webkit-scrollbar{
		width: 10px;
		height: 10px;
		background-color:#F5F5F5;
		display: none;
	}
	::-webkit-scrollbar-track{
		-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
		border-radius: 5px;
		background-color:#F5F5F5;
	}
	::-webkit-scrollbar-thumb{
		border-radius: 5px;
		-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
		background-color:rgba(0, 160, 233,1);
	}
}


html{
	font-size: 10px
}
@media (max-width: 1140px){
	html{
		font-size: 9px;
	}
}
@media (max-width: 992px){
	html{
		font-size: 8px;
	}
}
@media (max-width: 768px){
	html{
		font-size: 7px;
	}
}
@media (max-width: 576px){
	html{
		font-size: 6px;
	}
}


#app {
	width: 100%;
	height: 100%;
	font-family: Regular, '思源黑体', Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #333333;
	font-size: 16px;
}


// 根类名
.content{
	background: #F7F8F9;
}


// a标签
a{
	text-decoration: none !important;
	font-size: 16px;
	color: #282828;
}
video{
	object-fit: cover;
}


// 开发用边框
.dev-b-r{
	border: 1px solid red;
}
.dev-b-g{
	border: 1px solid green;
}
.dev-b-t{
	border: 1px solid transparent;
}


// 字体加粗
.com-font-bold{
	font-family: Medium;
}


// 颜色
.com-main-color{
	color: #333333;
}
.com-minor-color{
	color: #666666;
}
.com-assist-color{
	color: #999999;
}
.com-inverse-color{
	color: #FFFFFF;
}
.com-high-color{
	color: #00A0E9;
}


// 鼠标手型
.com-cursor-pointer{
	cursor: pointer;
}


// 顶部安全距离
.com-top-safety-distance{
	height: 9rem !important;
}
// 面包屑导航
.com-breadcrumb{
	background: var(--white,#fff);
	padding: 2rem 0;
	.breadcrumb{
		background: var(--white,#fff);
		font-size: 15px;
		.breadcrumb-item{
			padding-left: 1rem !important;
			a{
				font-size: 15px;
			}
			&::before {
				padding-right: 1rem;
				content: ">";
			}
			&:nth-child(2){
				padding-left: 0 !important;
				&::before {
					display: none;
				}
			}
		}
		.active{
			color: #5b5e5f;
		}
	}
}
// 头部背景图片模块标题
.com-header-bg-title{
	font-size: 36px;
	color: var(--white,#FFF);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-shadow: 0px 0px 4px rgba(0,0,0,.6);
	span:nth-child(1){
		position: relative;
		&:after{
			content: "";
			width: 20%;
			height: 4px;
			border-radius: 4px;
			background: #fff;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -10px;
		}
	}
	.en-title{
		margin-top: 40px;
		font-size: 18px;
		font-weight: normal;
		font-family: 宋体;
	}
}


h2{
	font-size: 20px !important;
	font-family: Medium;
}


/* 侧边栏 */
.suspension-box{
	position: fixed;
	bottom: 200px;
	right: 40px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	z-index: 100;
	.suspension-item{
		width: 60px;
		background: var(--theme);
		backdrop-filter: blur(6px);
		font-size: 14px;
		color:#fff;
		cursor: pointer;
		padding: 8px 5px 0 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		position: relative;

		border-radius: 0 !important;
		margin: 5px 0;
		background: rgba(0,0,0,.3) !important;
		padding: 12px 4px !important;
		// span{
		// 	// display: inline-block;
		// 	// padding-bottom: 8px;
		// 	// border-bottom: 1px solid rgb(110, 110, 110);
		// }

		&:first-child{
			border-radius: 8px 8px 0 0;
		}
		&:last-child{
			border-radius: 0 0 8px 8px;
			span{
				border-bottom: 1px solid transparent;
			}
		}
		&:hover{
			.position-box{
				display: block;
			}
		}
		.position-box{
			min-width: 20rem;
			display: none;
			position: absolute;
			right: 70px;
			top: 50%;
			transform: translate(0,-50%);
			// background: var(--theme);
			background: rgba(0,0,0,.3);
			backdrop-filter: blur(50px);
			padding: 1rem;
			border-radius: 8px;
			font-size: 14px;
			white-space: pre-wrap;
			img {
				width: 20rem;
				height: 20rem;
				border-radius: 4px;
			}
			&:after{
				content: "";
				width: 0;
				height: 0;
				border: 10px solid transparent;
				// border-left: 10px solid var(--theme);
				border-left: 10px solid rgba(0,0,0,.3);
				position: absolute;
				top: 50%;
				transform: translate(0,-50%);
				right: -20px;
			}
		}
	}
}
</style>