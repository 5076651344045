import { render, staticRenderFns } from "./FooterView.vue?vue&type=template&id=230fb722&scoped=true"
import script from "./FooterView.vue?vue&type=script&lang=js"
export * from "./FooterView.vue?vue&type=script&lang=js"
import style0 from "./FooterView.vue?vue&type=style&index=0&id=230fb722&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230fb722",
  null
  
)

export default component.exports