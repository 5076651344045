import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		logo: "",
		welcomeImg: "",
		nav: [],
	},
	getters: {
		LOGO: state => state.logo,
		WELCOMEIMG: state => state.welcomeImg,
		NAV:  state => state.nav,
	},
	mutations: {
		SET_LOGO(state,val){
            state.logo = val;
        },
		SET_WELCOMEIMG(state,val){
			state.welcomeImg = val;
		},
		SET_NAV(state,val){
			state.nav = val;
		}
	},
	actions: {
		setLogo({ commit }, val) {
			commit("SET_LOGO", val);
		},
		setWelcomeImg({ commit }, val){
			commit("SET_WELCOMEIMG", val);
		},
		setNav({ commit }, val) {
			commit("SET_NAV", val);
		}
	},
	modules: {
	}
})
