import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home.vue";
import config from "@/config/index";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "首页",
		component: HomeView,
		meta: {
			keepAlive: false
		}
	},
	// 概况
	{
		path: "/general",
		name: `初识${config.name}`,
		component: () => import("../views/General.vue"),
		meta: {
			keepAlive: false
		}
	},

	
	// 感知${config.name}
	{
		path: "/media",
		name: `感知${config.name}`,
		component: () => import("@/views/Media/Index.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/media/image",
		name: "美景图库",
		component: () => import("@/views/Media/Image.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/media/video",
		name: "精彩视频",
		component: () => import("@/views/Media/Video.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/media/strategy",
		name: "旅游攻略",
		component: () => import("@/views/Media/Strategy.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/media/detail",
		name: "攻略详情",
		component: () => import("@/views/Media/StrategyDetail.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/media/line",
		name: "旅游线路列表",
		component: () => import("@/views/Media/Line.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/media/gis",
		name: "景区手绘",
		component: () => import("@/views/Media/Gis.vue"),
		meta: {
			keepAlive: false
		}
	},


	// 列表
	{
		path: "/list",
		name: "列表",
		component: () => import("@/views/List/List.vue"),
		meta: {
			keepAlive: true
		}
	},
	{
		path: "/list/detail",
		name: "详情",
		component: () => import("@/views/List/Detail.vue"),
		meta: {
			keepAlive: false
		}
	},


	// 旅游指南
	{
		path: "/travelguide",
		name: "旅游指南",
		component: () => import("../views/TravelGuide/Index.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/travelguide/weather",
		name: "天气",
		component: () => import("@/views/TravelGuide/Weather.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/travelguide/complaint",
		name: "投诉",
		component: () => import("@/views/TravelGuide/Complaint.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/travelguide/trip",
		name: "出行",
		component: () => import("@/views/TravelGuide/Trip.vue"),
		meta: {
			keepAlive: false
		}
	},


	// 资源
	{
		path: "/resource",
		name: `智游${config.name}`,
		component: () => import("../views/Resource/Class.vue"),
		meta: {
			keepAlive: false
		}
	},


	// 资讯
	{
		path: "/news",
		name: "旅游资讯",
		component: () => import("@/views/News/News.vue"),
		meta: {
			keepAlive: false
		}
	},


	// 旅游活动
	{
		path: "/activity",
		name: "旅游资讯",
		component: () => import("@/views/Activity/List.vue"),
		meta: {
			keepAlive: false
		}
	},

	// 景区公示
	{
		path: "/publicity",
		name: "景区公示",
		component: () => import("@/views/Publicity/Index.vue"),
		meta: {
			keepAlive: true
		}
	},
	{
		path: "/publicity/detail",
		name: "公示详情",
		component: () => import("@/views/Publicity/Detail.vue"),
		meta: {
			keepAlive: false
		}
	},


	// 直播
	{
		path: "/live",
		name: "直播",
		component: () => import("@/views/Live/Index.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/live/list",
		name: "直播探头",
		component: () => import("@/views/Live/List.vue"),
		meta: {
			keepAlive: false
		}
	},


	// 我的
	{
		path: "/mine",
		name: "个人中心",
		component: () => import("@/views/Mine/Mine.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/login",
		name: "登陆",
		component: () => import("@/views/Mine/Login.vue"),
		meta: {
			keepAlive: false
		}
	},
	{
		path: "/regist",
		name: "注册",
		component: () => import("@/views/Mine/Regist.vue"),
		meta: {
			keepAlive: false
		}
	}
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		// 页面滚动到顶部
		$("body,html").animate({ scrollTop: 0 },300);
	}
})

export default router;