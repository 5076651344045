import jsEncrypt from "encryptlong";
import CryptoJS from "crypto-js/crypto-js";
import config from "@/config/index";

//根据加密方式解密数据
export function DecryptString(str) {
    let info = sessionStorage.getItem("SYSTEMSETINFO");
    let platformInfo = AesDecrypt(info, config.aesKey);
    let encryptType = platformInfo.encrypt;
    if (encryptType == 'AES') {
        // let content = AesDecrypt(str);
        // return JSON.parse(content);
        try {
            return JSON.parse(AesDecrypt(str));
        } catch (error) {
            return AesDecrypt(str);
        }
    } else if (encryptType == 'AES_RSA') {
        let content = RasDecryptLong(str);
        console.log(content)
        return content;
    }
}

// RAS加密
export function RasEncryptLong() {
    const encrypt = new jsEncrypt();
    encrypt.setPublicKey(getGetKeys('publicKey'));
}

// RAS解密
export function RasDecryptLong(str) {
    const encrypt = new jsEncrypt();
    encrypt.setPublicKey(getGetKeys('privateKey'));
    let rasContent = decodeURIComponent(encrypt.decryptLong(str))
    // let content = JSON.parse(AesDecrypt(rasContent));
    // return content;
    try {
        return JSON.parse(AesDecrypt(rasContent));
    } catch (error) {
        return AesDecrypt(rasContent);
    }
}

// AES加密
export function AesEncrypt(str, skey) {
    let key = skey ? CryptoJS.enc.Utf8.parse(skey) : getGetKeys('clientSecret');
    let encrypt = CryptoJS.AES.encrypt(encodeURIComponent(str), key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    let content = encrypt.toString();
    return content
}

// AES解密
export function AesDecrypt(str, skey) {
    let key = skey ? CryptoJS.enc.Utf8.parse(skey) : getGetKeys('clientSecret');
    let encrypt = CryptoJS.AES.decrypt(str, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let content = decodeURIComponent(CryptoJS.enc.Utf8.stringify(encrypt));
    return skey ? JSON.parse(content) : content;
}

// 截取或补充16的key
export function ChangeKey(key) {
    if (!key) {
        return ''
    }
    let aesKey = '';
    if (key.length >= 16) {
        aesKey = key.slice(0, 16);
    } else {
        aesKey = key;
        for (let i = 0; i < (16 - key.length); i++) {
            aesKey += 0;
        }
    }
    return aesKey
}

// 获取密钥
function getGetKeys(type) {
    let info = sessionStorage.getItem("SYSTEMSETINFO");
    let platformInfo = AesDecrypt(info, config.aesKey) 
    if (type != 'clientSecret') {
        return platformInfo[type]
    } else {
        let aesKey = '';
        let clientSecret = platformInfo[type]
        if (clientSecret.length >= 16) {
            aesKey = clientSecret.slice(0, 16);
        } else {
            aesKey = clientSecret;
            for (let i = 0; i < (16 - clientSecret.length); i++) {
                aesKey += 0;
            }
        }
        return CryptoJS.enc.Utf8.parse(aesKey)
    }
}