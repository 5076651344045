export default{
    play_text: `察尔汗盐湖，是中国青海省西部的一个盐湖，与茶卡盐湖齐名，位于柴达木盆地南部，地跨格尔木市和都兰县，
        由达布逊湖以及南霍布逊、北霍布逊、涩聂等盐池汇聚而成，总面积5856平方公里，
        格尔木河、柴达木河等多条内流河注入该湖。由于水分不断蒸发，盐湖上形成坚硬的盐盖，
        青藏铁路和青藏公路直接修建于盐盖之上，察尔汗盐湖蕴藏有丰富的氯化钠、氯化钾、氯化镁等无机盐，
        总储量达20多亿吨，为中国矿业基地之一。有著名的万丈盐桥。`,
    overview: [
        {   
            bgColor: '#183542', 
            name: '720全景', 
            explain: "察尔汗盐湖位于青海省格尔木市，是中国最大的盐湖，也是世界上最著名的内陆盐湖之一，与茶卡盐湖齐名，青藏铁路穿行而过。", 
            src: require('@/static/TravelGuide/tourguide-1.png'), 
            path: '/media/video?type=liveAction' 
        },
        {   
            bgColor: '#00A0E9', 
            name: '美景图库', 
            explain: `“察尔汗”是蒙古语，意为“盐泽”。盐湖地处戈壁瀚海，这里气候炎热干燥，日照时间长，水份蒸发量远远高于降水量。
                因长期风吹日晒，湖内便形成了高浓度的卤水，逐渐结晶成了盐粒，湖面板结成了厚厚的盐盖，异常坚硬。`, 
            src: require('@/static/TravelGuide/tourguide-2.png'), 
            path: '/media/image' 
        },
        {   
            bgColor: '#4AC524', 
            name: '景区手绘', 
            explain: `踞于巍巍昆仑山和祁连山之间的柴达木盆地，以青藏高原“聚宝盆”之誉蜚声海内外，而柴达木盆地的心脏则是赫赫有名的察尔汗。`, 
            src: require('@/static/TravelGuide/tourguide-3.png'), 
            path: '/media/gis' 
        }
    ],
}